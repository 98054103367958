* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // @include font-regular;
  // color: $color-secondary-dark;
}

*::before,
*::after {
  box-sizing: border-box;
}

html, body, #root, .App {
  height: 100%;
}

a {
	text-decoration: none;
	color: inherit;
}

svg {
	fill: rgba(26, 83, 92, 1);
}

@mixin desktop {
	@media screen and (min-width: 600px) {
	  @content;
	}
}

@mixin mobile {
	@media screen and (max-width: 600px) {
		@content;
	}
}

.portfolio {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
	flex-direction: column;
    width: 100%;
    background-color: rgba(255, 255, 255);
	padding: 1rem;

	@include desktop {
		flex-direction: row;
	}

    &__left {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      height: 3rem;
	  margin-right: 0.5rem;

	  @include desktop {
		  height: 5rem;
	  }

	  &--static {
		color: rgba(255, 107, 107, 1);
		font-size: 2.5rem;
		font-weight: bolder;
	  }
    }

	&__right {
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
		height: 3rem;

		@include desktop {
			height: 5rem;
		}

		&--dynamic {
			overflow: hidden; /* Ensures the content is not revealed until the animation */
			padding-right: 0.5rem;
			border-right: .15em solid rgba(255, 107, 107, 1); /* The typwriter cursor */
			white-space: nowrap; /* Keeps the content on a single line */
			// margin: 0 auto; /* Gives that scrolling effect as the typing happens */
			// letter-spacing: .15em; /* Adjust as needed */
			color: rgba(255, 107, 107, 1);
			font-weight: 600;
			font-size: 1rem;
			animation: 
			  typing 2s steps(40, end),
			  blink-caret .75s step-end infinite;

			@include desktop {
				white-space: nowrap;
				font-size: 2rem;
			}
		}
	}

  }

  &__contents {
    display: flex;
    flex-wrap: wrap;
	flex-direction: column;
	align-items: center;
    justify-content: space-evenly;
    position: relative;
    bottom: -5rem;

	@include desktop {
		flex-direction: row;
	}

    &__project {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border: 3px solid #4ECDC4;
		border-radius: 8px;
		width: 15rem;
		height: 20rem;
		padding: 1rem;
		margin: 1.5rem;
		background-color: #FEFECC;
		box-shadow: rgba(78, 205, 196, 0.4) -5px 5px, rgba(78, 205, 196, 0.3) -10px 10px, rgba(78, 205, 196, 0.2) -15px 15px, rgba(78, 205, 196, 0.1) -20px 20px, rgba(78, 205, 196, 0.05) -25px 25px;

		&:hover {
		box-shadow: rgba(78, 205, 196, 0.4) -10px 10px, rgba(78, 205, 196, 0.3) -15px 15px, rgba(78, 205, 196, 0.2) -20px 20px, rgba(78, 205, 196, 0.1) -25px 25px, rgba(78, 205, 196, 0.05) -30px 30px;
		}

		&__title {
			&__logo{
				width: 70%;

				&--records {
					width: 70%;
					padding-top: 1rem;
				}
			}
		}

		&__description {
			font-size: 1rem;
			font-weight: 400;

			&--hackathon {
				font-size: 0.75rem;
			}

			&--link {
				font-size: 0.9rem;
				font-weight: 600;
			}

			&--icon {
				height: 1rem;
				margin-left: 0.5rem;
			}
		}

		&__techstack {
			display: flex;

			&__logo {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 50%;

				&--angular {
					height: 3rem;
				}


				&--react {
					height: 3.5rem;
				}


				&--plaid {
					height: 2rem;
				}


				&--firebase {
					height: 3rem;
				}


				&--sass {
					height: 3rem;
				}
			}
		}
	}

	&__contact {

		&--mobile {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-end;

			@include desktop {
				display: none;
			}
		}

		&--desktop {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-end;
	
			@include mobile {
				display: none;
			}
		}

		&--social {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			height: 6rem;
			width: 6rem;
			margin: 1rem;
			background-color: #FEFECC;
			border: 3px solid #4ECDC4;
			box-shadow: rgba(78, 205, 196, 0.4) -5px 5px, rgba(78, 205, 196, 0.3) -10px 10px, rgba(78, 205, 196, 0.2) -15px 15px, rgba(78, 205, 196, 0.1) -20px 20px, rgba(78, 205, 196, 0.05) -25px 25px;
			
			&:hover {
				box-shadow: rgba(78, 205, 196, 0.4) -10px 10px, rgba(78, 205, 196, 0.3) -15px 15px, rgba(78, 205, 196, 0.2) -20px 20px, rgba(78, 205, 196, 0.1) -25px 25px, rgba(78, 205, 196, 0.05) -30px 30px;
			  }
		}
	}
  }

  &__footer {
    width: 100%;
    // border-top: 1px solid black;
    border-bottom: 200px solid #FFE66D;
    border-left: 75px solid transparent;
    border-right: 75px solid transparent;
  }

}

.circles {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.circles li {
	position: absolute;
	display: block;
	list-style: none;
	width: 20px;
	height: 20px;
	// background: rgba(96, 183, 199, 0.1);
	animation: animate 25s linear infinite;
	bottom: 0;
}

.circles li:nth-child(1) {
	left: 25%;
	width: 80px;
	height: 80px;
	animation-delay: 0s;
	animation-duration: 20s;
	border-top-left-radius: 0 !important;
  color: rgba(26, 83, 92, 0.3);
  font-size: 4rem;
  font-weight: 600;
	// background: rgba(47, 44, 92, 0.1);
}

.circles li:nth-child(2) {
	left: 10%;
	width: 20px;
	height: 20px;
	animation-delay: 0s;
	animation-duration: 25s;
	border-radius: 50%;
	border-top-left-radius: 0 !important;
  color: rgba(78, 205, 196, 0.3);
  font-size: 4rem;
  font-weight: 600;
	// background: rgba(96, 183, 199, 0.1);
}

.circles li:nth-child(3) {
	left: 70%;
	width: 20px;
	height: 20px;
	animation-delay: 0s;
	animation-duration: 20s;
	border-radius: 50%;
	border-top-left-radius: 0 !important;
  color: rgba(247, 255, 247);
  font-size: 4rem;
  font-weight: 600;
	// background: rgba(47, 44, 92, 0.1);
}

.circles li:nth-child(4) {
	left: 40%;
	width: 60px;
	height: 60px;
	animation-delay: 0s;
	animation-duration: 15s;
	border-radius: 50%;
	border-top-left-radius: 0 !important;
  color: rgba(255, 107, 107, 0.3);
  font-size: 4rem;
  font-weight: 600;
	// background: rgba(114, 63, 145, 0.1);
}

.circles li:nth-child(5) {
	left: 65%;
	width: 20px;
	height: 20px;
	animation-delay: 0s;
	animation-duration: 20s;
	border-radius: 50%;
	border-top-left-radius: 0 !important;
  color: rgba(255, 230, 109, 0.3);
  font-size: 4rem;
  font-weight: 600;
	// background: rgba(96, 183, 199, 0.1);
}

.circles li:nth-child(6) {
	left: 75%;
	width: 90px;
	height: 90px;
	animation-delay: 0s;
	animation-duration: 25s;
	border-radius: 50%;
	border-top-left-radius: 0 !important;
  color: rgba(26, 83, 92, 0.3);
  font-size: 4rem;
  font-weight: 600;
	// background: rgba(114, 63, 145, 0.1);
}

.circles li:nth-child(7) {
	left: 35%;
	width: 50px;
	height: 50px;
	animation-delay: 0s;
	animation-duration: 30s;
	border-radius: 50%;
	border-top-left-radius: 0 !important;
  color: rgba(78, 205, 196, 0.3);
  font-size: 4rem;
  font-weight: 600;
	// background: rgba(47, 44, 92, 0.1);
}

.circles li:nth-child(8) {
	left: 50%;
	width: 25px;
	height: 25px;
	animation-delay: 0s;
	animation-duration: 35s;
	border-radius: 50%;
	border-top-left-radius: 0 !important;
  color: rgba(247, 255, 247);
  font-size: 4rem;
  font-weight: 600;
	// background: rgba(114, 63, 145, 0.1);
}

.circles li:nth-child(9) {
	left: 20%;
	width: 15px;
	height: 15px;
	animation-delay: 0s;
	animation-duration: 40s;
	border-radius: 50%;
	border-top-left-radius: 0 !important;
  color: rgba(255, 107, 107, 0.3);
  font-size: 4rem;
  font-weight: 600;
	// background: rgba(47, 44, 92, 0.1);
}

.circles li:nth-child(10) {
	left: 85%;
	width: 50px;
	height: 50px;
	animation-delay: 0s;
	animation-duration: 45s;
	border-radius: 50%;
	border-top-left-radius: 0 !important;
  color: rgba(255, 230, 109, 0.3);
  font-size: 4rem;
  font-weight: 600;
	// background: rgba(96, 183, 199, 0.1);
}

.circles li:nth-child(11) {
	left: 35%;
	width: 50px;
	height: 50px;
	animation-delay: 0s;
	animation-duration: 45s;
	border-radius: 50%;
	border-top-left-radius: 0 !important;
  color: rgba(78, 205, 196, 0.3);
  font-size: 4rem;
  font-weight: 600;
	// background: rgba(96, 183, 199, 0.1);
}

.circles li:nth-child(12) {
	left: 80%;
	width: 50px;
	height: 50px;
	animation-delay: 0s;
	animation-duration: 45s;
	border-radius: 50%;
	border-top-left-radius: 0 !important;
  color: rgba(255, 107, 107, 0.3);
  font-size: 4rem;
  font-weight: 600;
	// background: rgba(96, 183, 199, 0.1);
}

@keyframes animate {
	0% {
		transform: translateY(0) rotate(0deg);
		opacity: 0;
	}
	50% {
		transform: translateY(-60vh) rotate(360deg);
		opacity: 1;
	}
	100% {
		transform: translateY(-120vh) rotate(720deg);
		opacity: 0;
	}
}

/* The typing effect */
@keyframes typing {
	from { width: 0 }
	to { width: 80% }
  }
  
  /* The typewriter cursor effect */
  @keyframes blink-caret {
	from, to { border-color: transparent }
	50% { border-color: rgba(255, 107, 107, 0.3); }
  }

  .video {
    width: 100vw;
    height: 100vh;
    background-color: rgba(35, 41, 64, 0.5);
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__modal {
		width: 90vw;
		height: 90vh;
        background-color: white;
        border-radius: 5px;
        padding: 2rem;

        &__close {
            display: flex;
            justify-content: flex-end;
        }

        &__content {
            display: flex;
			justify-content: center;
			align-items: center;
            width: 80vw;
            justify-content: space-between;
            margin-top: 2rem;
        }

        &__button {
            &--close { 
                background-color: white;
                border: none;
                font-size: 2rem;
                font-family: Arial, Helvetica, sans-serif;
            }

            &--choose {
                background-color: white;
                border: 1px solid black;
                border-radius: 5px;
            }

            &--upload {
                background-color: grey;
                border-radius: 5px;
                border: 1px solid white;
                color: white;
                padding: 0.5rem;
                font-size: 1rem;
                font-family: Arial, Helvetica, sans-serif;
            }

        }
    }
}